import Swiper from "swiper"
import { find } from "utils/dom"
import startTabs from "../modules/tabs"

export default function () {
	const Home = find("[js-page='single-produtos']")
	if (!Home) return
  const firstVariantsFilter = document.querySelectorAll('[js-filter-links] #produto-variante');
  const filterItemsContainer = document.querySelector('[js-filter-items]');
 
  startTabs(firstVariantsFilter, filterItemsContainer, "flex")

  const jsChangeColor = document.querySelectorAll('[js-change-color] > div')
  const jsTamanhosCores = document.querySelectorAll('[js-tamanhos-cores] #produto-variante')

  jsChangeColor.forEach(item => {
    item.addEventListener('click', () => {
      jsChangeColor.forEach(i => i.classList.remove('active'));
      
     
      const selectedFormat = item.getAttribute('data-color');
      item.classList.add('active');
      console.log('cor id :', selectedFormat);
      const thumb = document.querySelector(`.single-thumbs  .swiper-slide[data-color="${selectedFormat}"]`)
      if(thumb){
        const index = Array.from(thumb.parentNode.children).indexOf(thumb);
        swiperSingle.slideTo(index);
        // swiperSingle.slideTo(5)
      }
      let hasAvailableSize = false;
  
      jsTamanhosCores.forEach(tamanho => {
        const ids = tamanho.getAttribute('data-cores-disponiveis');
        const arrayIds = ids.split(',');
        console.log('tamanhoCorids :', arrayIds);
  
        // Check if selectedFormat is in arrayIds
        if (arrayIds.includes(selectedFormat)) {
          tamanho.classList.remove('desativarTamanho');
          if (!hasAvailableSize) {
            // Mark the first available size as active
            tamanho.classList.add('active');
            hasAvailableSize = true;
  
            // Simulate a click on the first available size
            tamanho.click();
          }
        } else {
          tamanho.classList.add('desativarTamanho');
        }
      });
  
      if (!hasAvailableSize) {
        // If no sizes are available, hide elements with [data-format]
        const dataFormatElements = document.querySelectorAll('#informacoes-tecnicas [data-format]');
        dataFormatElements.forEach(el => el.style.display = 'none');
      }
    });
  });

  // codigo aqui 

  // Função para voltar à página anterior
  function voltarPaginaAnterior() {
    window.history.back();
  }

  // Adiciona o listener ao botão "Voltar"
  const voltarBtn = document.querySelector('.botao-voltar'); 
  if (voltarBtn) {
    voltarBtn.addEventListener('click', voltarPaginaAnterior);
  }


}

