import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { find } from "utils/dom"

gsap.registerPlugin(ScrollTrigger);

export default function () {
	// neighborhood
	// neighborhood
	const Home = find("[js-page='home']")
	const Vagas = find("[js-page='vagas']")
	const esg = find("[js-page='esg']")


	function quemSomos() {
		const tl = gsap.timeline({
			scrollTrigger: {
				trigger: "#somos h3",
				start: "top 90%",
				// markers: true,
				// scrub:1,
			}
		})
		gsap.from("#somos .text-2", {
			scrollTrigger: {
				trigger: "#somos .text-2",
				start: "top 90%",
				// markers: true,
				// scrub:1,
			},
			opacity: 0,
			yPercent: 30,
			duration: 1,
		})
		tl.from("#somos .text-1 ", {
			opacity: 0,
			yPercent: 30,
			duration: 1,
			// stagger:0.1,
		})
			.from("#somos img", {
				opacity: 0,
				xPercent: 100,
				duration: 1,
			}, 0)
	}

	function bannerHome() {
		const tl = gsap.timeline({

		})

		tl.from("#banner-homer .banner-titulo-1", {
			opacity: 0,
			yPercent: 30,
			duration: 1,

		})
			.from("#banner-homer .banner-titulo-2", {
				opacity: 0,
				yPercent: 30,
				duration: 1,

			}, "<+=0.2")
			.from("#banner-homer .detalhe-titulo-l", {
				opacity: 0,
				x: -30,
				duration: 1,

			}, "<+=0.2")
			.from("#banner-homer .detalhe-titulo-r", {
				opacity: 0,
				x: 30,
				duration: 1,

			}, "<+=0.2")
			.from("#banner-homer h3", {
				opacity: 0,
				yPercent: 30,
				duration: 1,

			}, "<+=0.2")

	}

	function carrossell() {
		gsap.from("#swiper-banner .swiperBanner", {
			scrollTrigger: {
				trigger: "#swiper-banner .swiperBanner",
				start: "top 90%",
				// markers: true,
				// scrub:1,
			},
			opacity: 0,
			yPercent: 30,
			duration: 1,
			ease: "power1.out",
		})
	}

	function marcas() {
		const tl = gsap.timeline({
			scrollTrigger: {
				trigger: "#marcas_solucionadas",
				start: "top 90%",
				// markers: true,
				// scrub:1,
			}
		})
		gsap.set('#marcas_solucionadas .grid-titulo h2', {
			clipPath: "inset(0 100% 0 0)",
			opacity: 0,
			y: 20,

		})
		tl.to("#marcas_solucionadas .grid-titulo h2", {
			clipPath: "inset(0 0% 0 0)",
			opacity: 1,
			duration: 1.5,
			ease: "power1.out",
			y: 0,
		})
			.from("#marcas_solucionadas .grid-imagens div", {
				opacity: 0,
				y: 30,
				stagger: 0.2,
			}, "<")
	}

	function cutiveImg() {
		gsap.from("#banner", {
			scrollTrigger: {
				trigger: "#banner",
				start: "top 90%",
				// markers: true,
				// scrub:1,
			},
			opacity: 0,
			yPercent: 10,
			scale: 0.8,
			duration: 1,
			ease: "power1.out",
		})
	}


	function animaBeneficios() {
		console.log('opa ?')

		const tl = gsap.timeline({
			scrollTrigger: {
				trigger: "[anima-gsap-beneficios]",
				start: "top+=100px 90%",
				// markers: true,
				// scrub:1,
			},
			duration: 0.7
		})

		tl.from('[anima-gsap-beneficios] > p', { opacity: 0, y: 30, })
			.from('[anima-gsap-beneficios] .item-beneficio', { opacity: 0, y: 20, stagger: 0.2 }, '<+=0.2')
	}

	function animaNumeros() { 
		const items = document.querySelectorAll(".data");
	
		items.forEach(item => {
			const finalValue = parseInt(item.textContent.replace(/\D/g, ""), 10);
	
			gsap.fromTo(item,
				{ textContent: 0 }, // Começa em 0
				{
					textContent: finalValue, // Vai até o valor final
					duration: 1.5, // Duração da animação (reduzida para 1 segundo)
					ease: "power1.out",
					scrollTrigger: {
						trigger: item,
						start: "top 90%",
						toggleActions: "play none none none",
					},
					snap: { textContent: 1 }, // Arredonda para números inteiros durante a animação
					onUpdate: function () {
						// Formata o número para incluir separadores de milhar (ex: "5,600")
						item.textContent = Math.floor(item.textContent).toLocaleString();
					}
				}
			);
		});
	}
	

	function selos() {
		const containers = document.querySelectorAll('.selo-container');
	  
		gsap.from(containers, {
			opacity: 0,
			y: 50,
			duration: 0.8,
			stagger: 0.4,
			ease: "power3.out",
			scrollTrigger: {
				trigger: ".grid-selos",
				start: "top 80%",
				end: "bottom top",
				scrub: false,
			}
		});
	}


	  function programas() {
		
		const items = document.querySelectorAll('.grid-programas .flex');
	  
		gsap.from(items, {
		  opacity: 0, 
		  y: 50,  
		  duration: 0.8,  
		  stagger: 0.5,  
		  ease: "power3.out",  
		  scrollTrigger: {
			trigger: ".grid-programas", 
			start: "top 80%", 
			end: "bottom top", 
			scrub: false, 
		  }
		});
	  }

	  function animaGridPerguntas() {
		const perguntas = document.querySelectorAll(".grid-perguntas > .flex");
	  
		gsap.from(perguntas, {
		  scrollTrigger: {
			trigger: ".grid-perguntas",
			start: "top 70%", // Anima ao chegar perto da visualização
			toggleActions: "play none none none",
			// markers: true,
		  },
		  opacity: 0,
		  y: 50,
		  duration: 1,
		  stagger: 0.4, // Anima cada elemento com um intervalo de 0.3s
		  ease: "power3.out"
		});
	  }
	  
	  
	  


if (Home) {
	quemSomos()
	bannerHome()
	carrossell()
	marcas()
	cutiveImg()
}
if (Vagas) {
	animaBeneficios()
}
if (esg) {
	animaNumeros()
	selos()
	programas()
	animaGridPerguntas()
}
}

